(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name people.controller:PeopleCtrl
   *
   * @description
   *
   */
  angular
    .module('people')
    .controller('PeopleListCtrl', PeopleListCtrl);

  /* @ngInject */
  function PeopleListCtrl($http, $rootScope, $scope, $state, $stateParams, PAGE_SIZE, API_URL, Restangular, FileSaver, Blob) {

    var vm = this;

    vm.people = [];
    vm.exportExcel = exportExcel;
    vm.event = null;

    // md-data-table
    vm.search = {
      limit: PAGE_SIZE,
      order: 'name',
      page: $stateParams.p,
      total: 0,
      query: ''
    };

    vm.searchPeople = searchPeople;
    vm.query = query;
    vm.paginate = paginate;

    // Drop the case for a query string search
    if ($stateParams.q !== '') {
      vm.search.query = $stateParams.q.toLowerCase();
    }

    // Run the initial search
    searchPeople();

    // Run the search when the state changes
    // In the state (set in the routes file) we use reloadOnSearch = false so we can change states as the user types in the search field without losing focus on the input field
    $scope.$on('$locationChangeSuccess', function () {
      searchPeople();
    });

    // Run the search
    function searchPeople() {

      var args = {
        search: vm.search.query,
        limit: vm.search.limit,
        page: vm.search.page,
        total: true
      };

      if ($stateParams.eventId) {

        // Get the event
        vm.event = $rootScope.getEvent($stateParams.eventId);

        Restangular.one('events', $stateParams.eventId).getList('people', args).then(function (people) {
          vm.search.total = people.meta.total;
          vm.people = people;
        });
      } else {
        Restangular.all('people').getList(args).then(function (people) {
          vm.search.total = people.meta.total;
          vm.people = people;
        });
      }
    }

    // Callback for query changes
    function query() {
      vm.search.page = 1; // reset search to first page for new query
      $state.go('.', {q: vm.search.query, p: vm.search.page});
    }

    // Callback for md-on-paginate
    function paginate(page, limit) {
      $state.go('.', {q: $stateParams.q, p: page});
    }

    function exportExcel() {

      var q = '',
        fileName = 'People';
      if (vm.event) {
        q = '?event-id=' + vm.event.id;
        fileName += ' ' + vm.event.name;
      }

      $http.get(API_URL + '/people/export' + q, {responseType: 'arraybuffer'}).then(function (response) {
        var blob = new Blob([response.data], {type: response.headers('Content-type')});
        FileSaver.saveAs(blob, fileName + '.xlsx');
      }, function(error) {
          console.log('Download error', error);
      });
    }
  }
}());
